<template>
	<v-text-field class="right-aligned"
        hide-details dense outlined
        :label="label"
        :ref="name"
        v-model="inputValue"
        :placeholder="label"
        :reverse="reverse[format]"
        :prefix="prefix[format]"
        :suffix="suffix[format]"
        @blur="onBlur($event)"
        @changed="onChange($event)"
        @keypress="isAllowed($event)"
        autocomplete="new-password"
    ></v-text-field>
</template>
<script>
export default {
    name: 'CalcDecimalInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': false, 'date': false, 'decimal': false, 'euro': false, 'proz': false },
        prefix: { 'default': '', 'number': '#', 'date': '', 'decimal': '#', 'euro': '#', 'proz': '#' },
        suffix: { 'default': '', 'number': '', 'date': '', 'decimal': '', 'euro': '€', 'proz': '%' },
        inputValue: null,
    }),
    computed: {
    },
    mounted() {
    },
    methods: {
        getNewValue(value) {
            if (!value || value == '') return null
            return parseFloat(value.replace('.', '').replace(',', '.')).toFixed(2)

        },
        getInputValue(value) {
            if (!value || value == '') return null
            return parseFloat(value).toFixed(2).replace(',', '').replace('.', ',')
        },
        onBlur(e) {
            this.$emit('changed', this.getNewValue(this.inputValue))
        },
        onChanged(e) {
            //console.log('changed:', e)
        },
        isAllowed(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[0-9\,\-]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
    },
	watch: {
		value (val) {
            //console.log('watcher:', val, this.value, typeof(this.value))
            if(val) this.inputValue = this.getInputValue(this.value)
		},
	},
}
</script>
<style scoped>
.right-aligned >>> input {
    text-align: end
}
</style>